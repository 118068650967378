// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-brillea-js": () => import("./../../../src/pages/about-brillea.js" /* webpackChunkName: "component---src-pages-about-brillea-js" */),
  "component---src-pages-agency-index-js": () => import("./../../../src/pages/agency/index.js" /* webpackChunkName: "component---src-pages-agency-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-how-brillea-can-help-your-digital-marketing-js": () => import("./../../../src/pages/how-brillea-can-help-your-digital-marketing.js" /* webpackChunkName: "component---src-pages-how-brillea-can-help-your-digital-marketing-js" */),
  "component---src-pages-how-brillea-works-jsx": () => import("./../../../src/pages/how-brillea-works.jsx" /* webpackChunkName: "component---src-pages-how-brillea-works-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-referral-terms-and-conditions-js": () => import("./../../../src/pages/legal/referral-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-referral-terms-and-conditions-js" */),
  "component---src-pages-legal-terms-and-conditions-js": () => import("./../../../src/pages/legal/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-and-conditions-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-webinar-dec-2020-download-js": () => import("./../../../src/pages/webinar/dec-2020-download.js" /* webpackChunkName: "component---src-pages-webinar-dec-2020-download-js" */),
  "component---src-pages-webinar-dec-2020-js": () => import("./../../../src/pages/webinar/dec-2020.js" /* webpackChunkName: "component---src-pages-webinar-dec-2020-js" */),
  "component---src-pages-webinar-dec-2020-preview-js": () => import("./../../../src/pages/webinar/dec-2020-preview.js" /* webpackChunkName: "component---src-pages-webinar-dec-2020-preview-js" */),
  "component---src-templates-doc-category-js": () => import("./../../../src/templates/docCategory.js" /* webpackChunkName: "component---src-templates-doc-category-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

