import React from "react"
import { Link } from "gatsby"

import {
    Container,
    Navbar,
    Nav,
    NavItem,
    Button
  } from 'reactstrap';
import { LanguageMenu } from "../LanguageMenu";
import { Popup /* or Slider */ } from 'react-typeform-embed';
import EventbriteButton from '../react-eventbrite-popup-checkout';
import { UtmContext } from "../../utils/utmContext";
import { config } from "../../utils/const";
import localeContext from "../../utils/localeContext";


const Header = ({siteTitle, landing, landingLink, landingBtn, NoLang, webinarEventId}) => {

    const { locale } = React.useContext(localeContext);
    const [toggleMobNav , toggleMobNavSET] = React.useState(false)
    const { utm } = React.useContext(UtmContext)

    return (
    <>
    <Navbar className={(landing ? "fixed-top navbar-header navbar-header-landing" : "fixed-top navbar-header")} expand="md" style={{padding:"0px"}}>
        <Container style={{maxWidth:"100%"}}>

            <Link to={"/"} className={"navbar-brand"}>
                {siteTitle}
            </Link>

            {(!landing && 
            <div style={{display:'flex'}}>
                <Nav navbar className={toggleMobNav ? "is-active" : null }>
                    <NavItem>
                        <Link activeClassName="active" className="nav-link" to={"/how-brillea-works/"}>How it works</Link>
                    </NavItem>
                    <NavItem>
                        <Link activeClassName="active" className="nav-link" to={"/how-brillea-can-help-your-digital-marketing/"}>What you get</Link>
                    </NavItem>
                    <NavItem>
                        <Link activeClassName="active" className="nav-link" to={"/pricing/"}>Price</Link>
                    </NavItem>
                    <NavItem>
                        <Link activeClassName="active" className="nav-link" to={"/faqs/"}>FAQs</Link>
                    </NavItem>
                    <NavItem>
                        <Link activeClassName="active" className="nav-link" to={"/about-brillea/"}>About us</Link>
                    </NavItem>
                    <NavItem className="display-md">
                        <Link activeClassName="active" className="nav-link" to={"https://app.brillea.com"}>Sign in</Link>
                    </NavItem>
                    <NavItem className="display-md pt-2">
                        <LanguageMenu/>
                    </NavItem>
                </Nav>
                
                <button id="hamburger-nav-item" onClick={() => toggleMobNavSET(!toggleMobNav) } 
                className={toggleMobNav ? "hamburger is-active" : "hamburger"}
                type="button">
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
            </div>
            )}
            <div className="navBtns" style={{display:"flex"}}>
                {(webinarEventId ? (
                    <EventbriteButton
                        className="btn btn-primary"
                        ebEventId={webinarEventId}>
                        Get your free ticket
                    </EventbriteButton>
                ) : (
                    <Popup
                        id={(locale === 'en-US' ? config.typeform.getstarted.US : config.typeform.getstarted.UK)}
                        size={95} 
                        hidden={{ utm }}
                        tooltip={"Get started with brillea"}
                        onReady={() => { window.dataLayer.push({ event: "openForm", CTA: "header", }) }}
                        onSubmit={() => { window.dataLayer.push({ event: "submitForm", CTA: "header", }) }}
                    >
                    {/* <Button tag={Link} to={(landingLink ? landingLink : "/contact/")} color="primary">{(landingBtn ? landingBtn : "Get Started")}</Button> */}
                        <Button color="primary">{(landingBtn ? landingBtn : "Get Started")}</Button>
                    </Popup>
                ))}
                {(!landing && 
                <Button className="hidden-md" color="secondary" to="https://app.brillea.com" tag={Link}>Sign in</Button>
                )}
                {(!NoLang && 
                <div className="hidden-md LanguageMenu">
                    <LanguageMenu flag/>
                </div>
                )}
            </div>

        </Container>
    </Navbar>
    </>
   );
// }
}
export default Header;
