
import React from "react"
import { Link } from "gatsby"
import {
    Container,
    Navbar,
    NavItem,
    Row,
    Col,
    Button
  } from 'reactstrap';
import { LanguageMenu } from "../LanguageMenu";
import { Popup /* or Slider */ } from 'react-typeform-embed';
import { UtmContext } from "../../utils/utmContext";
import { config } from "../../utils/const";
import localeContext from "../../utils/localeContext";

const Footer = ({siteTitle, landingLink, landing, NoLang}) => {
    const { locale } = React.useContext(localeContext);
    const { utm } = React.useContext(UtmContext);
return(
          <>
    <footer>
        <Container>
            <Row className={(landing ? "footer_landing_page" : "")}>
                <Col lg={{size:3}} md={{size:12}} className="footer_brand_wrapper">
                    <p className="brillea_brand">
                        <Link to={"/"}>brillea</Link>
                    </p>
                    <p className="copyright">Copyright &copy; {new Date().getFullYear()}, {siteTitle}.</p>
                    <p className="legal">
                        <a href={"/legal/"} target="_blank" rel="noreferrer">Legal stuff</a> | 
                        <a href={"/legal/privacy-policy/"} target="_blank" rel="noreferrer">Privacy policy</a>  
                    </p>
                    {(!NoLang &&
                    <LanguageMenu/>
                   )}
                </Col>
                {(!landing &&
                <>
                <Col lg={{size:3}} md={{size:6}} sm={{size:6}} xs={{size:6}}>
                    <h4>Popular</h4>
                    <Navbar>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/how-brillea-can-help-your-digital-marketing/"}>How we can help you</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/how-brillea-works/"}>How it works</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/pricing/"}>Price</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/faqs/"}>FAQs</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/contact/"}>Contact us</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/about-brillea/"}>About us</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/blog/"}>Blog</Link>
                        </NavItem>
                    </Navbar>
                </Col>
                <Col lg={{size:3}} md={{size:6}} sm={{size:6}} xs={{size:6}}>
                    <h4>Free tools</h4>
                    <Navbar>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/help/how-to-get-set-up-on-google-ads/"}>Help guides</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/pricing/#spend_calculator"}>Marketing spend calculator</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/help/how-to-get-set-up-on-google-ads/"}>How To Set Up Google Ads</Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link underline_hover" to={"/help/how-to-get-set-up-on-facebook-ads/"}>How To Set Up Facebook Ads</Link>
                        </NavItem>
                    </Navbar>
                </Col>
                <Col lg={{size:3}} md={{size:12}} className="footer_cta">
                    <Popup 
                        id={(locale === 'en-US' ? config.typeform.getstarted.US : config.typeform.getstarted.UK)}
                        size={95} 
                        hidden={{ utm }}
                        tooltip={"Get started with brillea"}
                        onReady={() => { window.dataLayer.push({ event: "openForm", CTA: "footer", }) }}
                        onSubmit={() => { window.dataLayer.push({ event: "getStartedFormComplete", CTA: "footer", }) }}
                    >
                        <Button block>Get Started</Button>
                    </Popup>
                   <p className="footer_login">already a brillea customer? <a href={"https://app.brillea.com"} target="_blank" rel="noreferrer">Login</a></p>
                </Col>
                </>
                 )}
            </Row>
        </Container>
    </footer>

          </>
   );
}
export default Footer;