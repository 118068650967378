import React from "react"
import { StaticQuery, graphql } from "gatsby"

import {UtmContext} from "../utils/utmContext";
import Header from "./Header/header"
import Footer from "./Footer/footer"

import CookieConsent from "react-cookie-consent"

import "../../static/styles/main.scss"
import 'aos/dist/aos.css'

import { config } from "@fortawesome/fontawesome-svg-core";
import withI18next from "../i18n/withI18next";

config.autoAddCss = false;

const Layout = ({ wrapperClass, children, location, NoFooter, pageContext}) => {

  const [utm, utmSet] = React.useState(false);
  const [utmLoaded, utmLoadedSet] = React.useState(false);

  React.useEffect(() => {

    if(location.search){

      utmSet(location.search);
      localStorage.setItem('utm', location.search );
      utmLoadedSet(true);

    } else {
      if(!utm && localStorage.getItem('utm')){

        let applicationUtm = JSON.stringify( localStorage.getItem('utm') )
        utmSet( (applicationUtm.slice(0,1) === '?' ? applicationUtm : '?' + applicationUtm ) );
      }

      utmLoadedSet(true);
    }

  },[location, utm, utmSet]);

  return (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <UtmContext.Provider value={{
          utm,
          utmSet,
          utmLoaded,
          utmLoadedSet
        }}>
        <div className={wrapperClass}>
          <Header siteTitle={data.site.siteMetadata.title} landing={pageContext.landing} landingLink={pageContext.landingLink} landingBtn={pageContext.landingBtn} NoLang={pageContext.NoLang} webinarEventId={pageContext.webinarEventId}/>
          <main>{children}</main>
          {(NoFooter ? null : <Footer siteTitle={data.site.siteMetadata.title} location={location} landing={pageContext.landing} NoLang={pageContext.NoLang}/>)}
          <CookieConsent
            location="bottom"
            buttonText="Got it"
            cookieName="CookieConsent"
            style={{ background: "#2B373B" }}
            expires={150}
          >
            This website uses cookies to ensure you get the best experience on
            our website.{" "}
            <a href="/legal/cookie-policy/" target="_blank">
              Learn more
            </a>
          </CookieConsent>
        </div>
        </UtmContext.Provider>
      </>
    )}
  />
);
}; 

// export default Layout
export default withI18next()(Layout);