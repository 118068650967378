import i18n from "i18next";
import Cache from 'i18next-localstorage-cache';

export default () => {
  i18n
  .use(Cache)
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en-GB",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: { useSuspense: false }
  });

  return i18n;
};
