import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactCountryFlag from "react-country-flag"

import localeContext from "../utils/localeContext";


export const LanguageMenu = ({flag}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

  return(
    <localeContext.Consumer>
        {({ locale, setLanguage }) => (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className={flag && " dropdown_flag"}>
        <DropdownToggle caret className={(flag ? "btn-link flag" : "btn-link")} tag="a" >
          {(flag ? (
            <><ReactCountryFlag countryCode={(locale === 'en-US' ? 'US' : 'GB')} /></>
          ) : (
            <><FontAwesomeIcon icon={faLocationArrow}/> {(locale === 'en-US' ? 'United States' : 'United Kingdom')}</>
          ))}
        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem onClick={() => setLanguage("en-GB")}>
              <ReactCountryFlag countryCode="GB" /> United Kingdom
            </DropdownItem>
            <DropdownItem onClick={() => setLanguage("en-US")}>
              <ReactCountryFlag countryCode="US" /> United States
            </DropdownItem>
        </DropdownMenu>
        </Dropdown>
     )}
    </localeContext.Consumer>
  )
}